
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import router from '@/router'
import CkeditorModule from '@/components/CkeditorModule.vue'
import { getPage, Page } from '@/kentico-api/submenus.api'
import { AxiosResponse } from 'axios'
import { onBeforeRouteUpdate } from 'vue-router'

export default defineComponent({
  components: { Spinner, GenericError, PageHeader, CkeditorModule },
  props: {
    slug: {
      type: String,
      required: true
    },
    redirectOnError: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const page = ref()
    const error = ref()

    const loadPageContent = (slug: string) => {
      getPage(slug)
        .then(({ data }: AxiosResponse<Page>) => {
          if (!Object.keys(data).length) {
            if (props.redirectOnError) {
              router.replace({ name: 'Not Found' })
              return
            }
            error.value = true
            return
          }

          page.value = data
        })
        .catch(e => {
          error.value = e
        })
    }

    onMounted(() => {
      document.body.classList.add('bg-transparent')
      const oneTrust = document.getElementById('onetrust-consent-sdk')
      if (oneTrust) {
        oneTrust.style.display = 'none'
      }
    })

    onBeforeUnmount(() => {
      document.body.classList.remove('bg-transparent')
      const oneTrust = document.getElementById('onetrust-consent-sdk')
      if (oneTrust) {
        oneTrust.style.display = 'block'
      }
    })

    loadPageContent(props.slug)

    onBeforeRouteUpdate(to => { loadPageContent(to.params.slug as string) })

    return { page, error }
  }
})
